<template>
    <div class="menu-area">
        <a-menu class="menu" mode="inline" :defaultOpenKeys="defaultOpenKeys" v-model="select" :inline-collapsed="collapsed" @click="handleClick">
            <component
              :is="m.children ? 'a-sub-menu' : 'a-menu-item'"
              v-for="m in menu"
              :key="m.path">
                <template v-if="m.children">
                    <div slot="title" class="menu-title">
                        <div class="menu-icon" :style="{backgroundImage: `url(${m.icon || ''})`}"></div>
                        <span>{{m.title}}</span>
                    </div>
                    <component :is="c.children ? 'a-sub-menu' : 'a-menu-item'" v-for="c in m.children" :key="c.path">
                        <template v-if="c.children">
                            <span slot="title">{{c.title}}</span>
                            <component
                                :is="s.children ? 'a-sub-menu' : 'a-menu-item'"
                                v-for="s in c.children"
                                :key="s.path">
                                <template v-if="s.children">
                                    <span slot="title">{{s.title}}</span>
                                    <a-menu-item v-for="d in s.children" :key="d.path">{{d.title}}</a-menu-item>
                                </template>
                                <template v-else>
                                    <span>{{s.title}}</span>
                                </template>
                            </component>
                            <!-- <a-menu-item v-for="s in c.children" :key="s.path">{{s.title}}</a-menu-item> -->
                        </template>
                        <template v-else>
                            <span>{{c.title}}</span>
                        </template>
                    </component>
                </template>
                <template v-else>
                    <div class="menu-title">
                        <div class="menu-icon" :style="{backgroundImage: `url(${m.icon || ''})`}"></div>
                        <span>{{m.title}}</span>
                    </div>
                </template>
            </component>
        </a-menu>
    </div>
</template>

<script>
    export default {
        name: "menu-bar",
        data() {
            return {
                defaultOpenKeys: [],
                select: [this.$route.path]
            }
        },
        computed: {
            menu() {
                return this.$store.getters.menus;
            },
            collapsed () {
                return this.$store.getters.collapsed;
            },
        },
        watch: {
            $route(val) {
                let path = val.path;
                if (this.isSpecailSubMenu(path, "/safe/[^/]+") || this.isSpecailSubMenu(path, "/asset/outbound/use") || this.isSpecailSubMenu(path, "/asset/outbound/borrow")) {
                    this.setSelect(path);
                    return;
                }
                this.select = [path];
            }
        },
        created() {
            this.setOpenMenu(this.$route.path);
        },
        methods: {
            isSafe(path) {
                return path == "/safe";
            },
            isAsset(path) {
                return path == "/asset";
            },
            isSpecailSubMenu(path, prefix) {
                const regex = new RegExp(`^${prefix}/[^/]+$`);
                return regex.test(path);
            },
            setSelect(path) {
                const paths = path.split("/");
                paths.splice(-1);
                let menu = paths.join("/");
                this.select = [menu];
            },
            setOpenMenu(path) {
                let menu = this.menu;
                loop: for(let i = 0, l = menu.length; i < l; i ++) {
                    let m = menu[i];
                    if(m.path == path) {
                        break;
                    } else if(m.children) {
                        for (let j = 0, len = m.children.length; j < len; j++) {
                            let child = m.children[j];
                            if (child.path == path) {
                                this.defaultOpenKeys = [m.path];
                                break loop;
                            } else if (child.children) {
                                for (let k = 0, length = child.children.length; k < length; k++) {
                                    let nextChild = child.children[k];
                                    let safeMenu = this.isSafe(m.path) && this.isSpecailSubMenu(path, "/safe/[^/]+");
                                    let assetMenu = this.isAsset(m.path) && this.isSpecailSubMenu(path, "/asset/[^/]+/[^/]+");
                                    if (nextChild.path == path) {
                                        this.defaultOpenKeys = [m.path, child.path];
                                        break loop;
                                    } else if (safeMenu) {
                                        // 安全菜单特殊处理
                                        this.setSelect(path);
                                        if (this.isSpecailSubMenu(path, "/safe/car")) {
                                            // 车辆管理菜单特殊处理
                                            this.defaultOpenKeys = [m.path, "/safe/business"];
                                        } else {
                                            this.defaultOpenKeys = [m.path, "/safe/board"];
                                        }
                                        break loop;
                                    } else if (assetMenu) {
                                        // 资产菜单
                                        this.setSelect(path);
                                        if (this.isSpecailSubMenu(path, "/asset/outbound/use") || this.isSpecailSubMenu(path, "/asset/outbound/borrow")) {
                                            this.defaultOpenKeys = [m.path, "/asset/outbound"];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            handleClick(e) {
                let path = e.key;
                if(path != this.$route.path) {
                    let item = this.findMenuItem(this.menu, path);
                    if(item && item.blank) {
                        window.open(path, "_blank");
                    } else if(item){
                        this.$router.push(path);
                    }
                }
            },
            findMenuItem(menus, path) {
                let res = null;
                for(let i = 0, l = menus.length; i < l; i++) {
                    let item = menus[i];
                    if(item.path == path) {
                        res = item;
                        break;
                    } else if(item.children) {
                        res = this.findMenuItem(item.children, path);
                        if(res) break;
                    }
                }
                return res;
            },
        }
    }
</script>

<style lang="less">
    .menu-area {
        flex-shrink: 0;
        position: relative;
        background-color: #FFDDA5;
        .menu {
            position: absolute;
            padding: 12px 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            color: #3B2120;
            font-size: 18px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 1;
            //滚动条
            &::-webkit-scrollbar {
                width: 4px;
                height: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FF8C00;
                border-radius: 5px;
            }
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 213px;
            height: 167px;
            background: url(../assets/temple.png) center no-repeat;
        }
        .ant-menu-submenu-selected,
        .ant-menu-item-selected {
            color: #FF8C00;
        }
        .ant-menu-submenu-title:hover,
        .ant-menu-item:hover {
            color: #FF8C00;
        }
        .ant-menu-item:active,
        .ant-menu-submenu > .ant-menu,
        .ant-menu-submenu-title:active,
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: transparent;
        }
        .ant-menu-inline .ant-menu-item::after {
            border-color: #FF8C00;
        }

        .ant-menu-submenu-inline  {
            & > .ant-menu-submenu-title {
                .ant-menu-submenu-arrow {
                    &::before,
                    &::after {
                        content: "";
                        background-image: none;
                        width: 10px;
                        height: 1px;
                        border-radius: 1px;
                    }
                    &::before {
                        content: "";
                        transform: rotate(-40deg) translateX(5px);
                    }
                    &::after {
                        content: "";
                        transform: rotate(40deg) translateX(-5px);
                    }
                }
            }
            & > .ant-menu-submenu-title:hover {
                .ant-menu-submenu-arrow {
                    &::before {
                        content: "";
                        background-color: #FF8C00;
                    }
                    &::after {
                        content: "";
                        background-color: #FF8C00;
                    }
                }
            }
            &.ant-menu-submenu-open {
                & > .ant-menu-submenu-title {
                    .ant-menu-submenu-arrow {
                        &::before {
                            content: "";
                            transform: rotate(40deg) translateX(5px);
                        }
                        &::after {
                            content: "";
                            transform: rotate(-40deg) translateX(-5px);
                        }
                    }
                }
            }
        }

    }
    .second-menu {

    }
    .menu-title {
        display: flex;
        align-items: center;
    }
    .menu-icon {
        margin-right: 10px;
        width: 26px;
        height: 26px;
        background: center no-repeat;
        background-size: contain;
    }
</style>

<template>
  <div class="nav-bar">
    <div class="flex-box align-center nav-left">
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" class="collapsed-icon" style="color: #FFCE7E"/>
      <div class="screen-list">
        <div class="screen-item" v-for="screen in screenList" :key="screen.path" @click="screenClick(screen)">{{screen.title}}</div>
      </div>
    </div>
    <a-space class="nav-user" @click.stop="showMenu" v-if="user">
      <img src="../assets/avatar.png" class="nav-avatar">
      <div class="user-name">
        <open-data class="name-txt" type="userName" :openid="user.wx_user_id" />
        <!--                <div class="name-txt">{{user.name}}</div>-->
        <div class="name-auth">{{user.roleText}}</div>
      </div>
      <div class="icon-down"></div>
    </a-space>
    <div class="down-box" v-show="ms">
      <div class="down-box-item" @click.stop="ModifyPassword">
        <a-icon type="edit" />
        <div class="down-txt">修改密码</div>
      </div>
      <div class="down-box-item" @click.stop="logout">
        <a-icon type="logout" />
        <div class="down-txt">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {cleanToken, getStorageInfo} from "../common/js/storage";
import {getRoleTypeText} from "../common/constant/org";

export default {
  name: "nav-bar",
  data() {
    return {
      menu: [],
      ms: false,
      templeId: getStorageInfo().temple.crop_id
    }
  },
  computed: {
    linkList() {
      let templeId = this.templeId;
      let res = [
          {
              title: '综合大屏',
              path: '/screen',
          },
          {
              sys: 1,
              title: '协同大屏',
              path: '/screen?sub=oa',
          },
          {
              sys: 2,
              title: '人事大屏',
              path: '/screen?sub=hr',
          },
          {
              sys: 5,
              title: '佛事大屏',
              path: '/screen?sub=buddha',
          },
          {
              title: '资产大屏',
              path: '/screen?sub=asset',
          },
          {
              title: '安全大屏',
              path: '/screen?sub=safe',
          },
          {
              title: '文化大屏',
              path: '/screen?sub=culture',
          }
      ];
      //佛学院暂时只显示综合大屏
      if(templeId === "wpLnbtCQAAIAZrNVQl2p3Zw1SwYEDz0Q") {
          res = [
              {
                  title: '综合大屏',
                  path: '/screen',
              },
          ]
      }
      return res;
    },
    user () {
      let user = this.$store.getters.user;
      if(user) {
        user.roleText = getRoleTypeText(user)
      }
      return user;
    },
    screenList() {
      let res = [];
      let user = this.user;
      let linkList = this.linkList;
      if(user.role == 1) {
        res = linkList;
      } else if(user.userRole && user.userRole.length > 0) {
        user.userRole.forEach(r => {
          // 权限为子系统管理员
          if(r.privilege == 1) {
            let link = linkList.find(l => l.sys && l.sys == r.system);
            link && res.push(link);
          }
        })
      }
      return res;
    },
    collapsed () {
      return this.$store.getters.collapsed;
    },
  },
  created() {
    document.addEventListener('click', this.hideMenu, false);
    // wxConfig([]);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideMenu, false);
  },
  methods: {
    screenClick(screen) {
      window.open(screen.path, '_blank')
    },
    changeCollapsed() {
      this.$store.commit("changeCollapsed", !this.collapsed);
    },
    ModifyPassword() {
      this.$router.push({name: 'Password'});
      this.hideMenu();
    },
    logout() {
      cleanToken();
      location.replace( '/login');
    },
    showMenu() {
      this.ms = !this.ms;
    },
    hideMenu() {
      this.ms = false;
    }
  }
}
</script>

<style lang="less">
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 @padding-md;
  height: 60px;
  background-color: #FDFCF6;
  box-shadow: @box-shadow-base;
  z-index: 10;
}
.screen-list {
  margin-left: 24px;
  padding-left: 24px;
  line-height: 1;
}
.screen-item {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  color: #978A83;
  &:not(:first-child) {
    margin-left: 30px;
  }
  &:hover {
    color: #FFCE7E;
  }
}
.logo {
  display: flex;
  align-items: center;
  height: 100%;
}
.collapsed-icon {
  font-size: 20px;
  cursor: pointer;
}
.nav-user {
  position: relative;
  padding: 0 8px;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  .icon-down {
    font-size: 8px;
    color: #FFCE7E;
  }
}
.nav-avatar {
  width: 28px;
  height: 28px;
}
.name-auth {
  color: #978A83;
  font-size: 12px;
}
.user-down {
  margin-left: 4px;
  font-size: 16px;
}
.name-down {
  font-size: 16px;
  color: #FFCE7E;
}
.down-box {
  position: absolute;
  top: 68px;
  right: 24px;
  min-width: 160px;
  border: var(--border);
}
.down-box-item {
  display: flex;
  align-items: center;
  padding: @padding-sm @padding-md;
  cursor: pointer;
  &:hover {
    background-color: @background-color-light;;
  }
}
.down-txt {
  margin-left: 8px;
}
</style>

<template>
  <div class="detail">
    <div class="detail-back" @click="hideDetail"></div>
    <div class="detail-ctx" v-if="apply">
      <a-icon class="icon-close" type="close" @click="hideDetail" />
      <apply-info class="flex-grow scroll-area" :info="info" :report="report" @show="handleRelatedApplyShow"></apply-info>
      <a-space class="detail-operate" v-if="relatedApply">
        <a-button @click="hideRelatedApply">返回</a-button>
      </a-space>
      <a-space class="detail-operate" v-else-if="info.btns && info.btns.length > 0">
        <a-button :type="i % 2 == 0 ? 'default' : 'primary'" v-for="(btn, i) in info.btns" :key="btn.type" @click="operate(btn)">{{btn.title}}</a-button>
      </a-space>
    </div>
    <a-modal
      :title="title"
      :maskClosable="false"
      :closable="false"
      :width="600"
      @cancel="handleCancel"
      @ok="handleConfirm"
      v-model="visible">
      <form-area
        ref="nodeForm"
        class="flex-box vertical"
        layout="horizontal"
        hide-btn
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
        :items="items"
        :entity="form"></form-area>
    </a-modal>
  </div>
</template>

<script>
import applyInfo from "./apply-info";
import {clone, randomString} from "../../common/js/tool";
import {nodeRoleIcon, dealApply, dealReport} from "../../common/js/apply";
import {nodeRoles} from "../../common/constant/process";

export default {
  name: "apply-detail",
  components: {
    applyInfo
  },
  props: {
    apply: Object,
    report: Boolean
  },
  data() {
    return {
      current: null,
      title: null,
      visible: false,
      loading: false,
      opinion: null,
      form: {},
      type: null,
      btnType: null,
      selectNode: null,
      roleIcon: nodeRoleIcon,
      relatedApply: null,
    }
  },
  computed: {
    items() {
      let res = [];
      let type = this.type;
      switch (type) {
        case 1:
          res = [
            {
              key: 'note',
              label: '审批意见',
              component: 'a-textarea',
              props: {
                placeholder: '请输入审批意见',
                rows: 4
              }
            },
          ];
          break;
        case 2:
          res = [
            {
              key: 'note',
              label: '审批意见',
              component: 'a-textarea',
              props: {
                placeholder: '请输入审批意见',
                rows: 4
              }
            },
            {
              key: 'user',
              label: '接单人',
              component: 'select-book',
              props: {
                type: "user"
              },
              rules: [{required: true, message: '请选择接单人', trigger: 'change'}]
            }
          ];
          break;
        case 3:
          res = [
            {
              key: 'type',
              label: '节点类型',
              component: 'a-select',
              props: {
                placeholder: '请选择节点类型',
                options: nodeRoles.slice(0,3)
              },
              style: {
                width: '100%'
              },
              listeners: {
                change(val) {
                  let index = this.controls.find(c => c.key == 'custom_next');
                  if(val == 1) {
                    if(index === -1) {
                      this.controls.push({
                        key: 'custom_next',
                        label: '其他',
                        component: 'a-checkbox-group',
                        props: {
                          options: [{label: '允许此节点指定下一个节点', value: 1}]
                        }
                      })
                    }
                  } else {
                    this.controls.splice(index, 1);
                  }
                }
              },
              rules: [{ required: true, message: '请选择节点类型', trigger: 'change' }]
            },
            {
              key: 'users',
              label: '节点成员',
              component: 'select-book',
              props: {
                multiple: true,
                type: "user"
              },
              rules: [{ required: true, message: '请选择节点成员', trigger: 'change' }]
            }
          ]
          break;
      }
      return res;
    },
    info() {
      const apply = this.relatedApply || this.apply;
      if(apply) {
        if(this.report) {
          dealReport(apply);
        } else {
          if(!apply.form) {
            dealApply(apply);
          }
        }
        return clone(apply);
      } else {
        return null;
      }
    }
  },
  watch: {
    apply() {
      this.hideRelatedApply();
    }
  },
  methods: {
    hideRelatedApply() {
      this.relatedApply = null;
    },
    handleRelatedApplyShow(apply) {
      this.relatedApply = apply;
    },
    hideDetail() {
      this.$emit('hide');
    },
    handleCancel() {
      this.btnType = null;
      this.type = null;
      this.form = {};
    },
    handleConfirm() {
      this.$refs.nodeForm.handleConfirm().then(form => {
        let btnType = this.btnType;
        switch (btnType) {
          case "addNode":
            this.addNode(form)
            break;
          case "refer":
          case "dispatch":
            this.referApply(btnType == "refer" ? 3 : 1, form);
            break;
          default:
            this[`${btnType}Apply`](form);
        }
        this.visible = false;
      });
    },
    addNode(data) {
      let role = nodeRoles.find(n => n.type == data.type);
      let node = {
        type: data.type,
        title: role.title,
        ids: data.users,
        node_id: this.generateNodeId(role.name)
      }
      if (data.type == 1) {
        node.approval_type = 1;
      }
      data.custom_next && (node.custom_next = 1);
      this.selectNode = node;
    },
    generateNodeId(name) {
      let str = `${name}_${randomString(6)}`;
      while (this.info && this.info.flow && this.info.flow.some(n => n.node_id == str)) {
        str = this.generateNodeId(name);
      }
      return str;
    },
    operate(btn) {
      if (!this.info.message || this.loading) return;
      let type = btn.type;
      switch (type) {
        case "refer":
        case "dispatch":
          this.type = 2;
          break;
        default:
          this.type = 1;
      }
      this.form = {};
      this.title = btn.title;
      this.btnType = type;
      this.visible = true;
    },
    rejectApply(form) {
      this.$confirm({
        title: '提示',
        content: '驳回后，审批流程将终止。',
        onOk: () => {
          this.operateMessage({
            note: form.note,
            apply_status: 2
          }).then(() => {
            this.$message.success("已驳回")
          });
        },
        onCancel: () => {
          this.loading = false;
        }
      });
    },
    agreeApply(form) {
      let {info, selectNode} = this;
      if (info.custom) {
        if (selectNode) {
          this.operateFlow({
            node: selectNode,
            note: form.note,
            status: 1
          }).then(() => {
            this.$message.success("已同意");
            this.selectNode = null;
          });
        } else {
          this.$confirm({
            title: '提示',
            content: '当前节点可指定下一个节点，您尚未添加下一个节点，同意后流程将结束。',
            onOk: () => {
              this.operateMessage({
                note: form.note,
                apply_status: 1
              }).then(() => {
                this.$message.success("已同意");
              });
            },
            onCancel: () => {
              this.loading = false;
            }
          });
        }
      } else {
        this.operateMessage({
          note: form.note,
          apply_status: 1
        }).then(() => {
          this.$message.success("已同意");
        });
      }
    },
    completeApply() {
      this.$confirm({
        title: '提示',
        content: '确定完成此流程吗？',
        onOk: () => {
          let info = this.info;
          if(info.extraApply && info.extraApply.findIndex(a => a.status == 1) >= 0) {
            this.$message.warning("流程中尚有需求单未结束，请确认全部需求单结束后再完成此流程！");
            return;
          }
          this.operateMessage({
            is_complete: 1
          }).then(() => {
            this.$message.success("已完成");
          });
        },
        onCancel: () => {
          this.loading = false;
        }
      });
    },
    orderApply(form) {
      this.operateMessage({
        note: form.note,
        apply_status: 1
      }).then(() => {
        this.$message.success("已接单");
      });
    },
    referApply(status = 3, form) {
      let node = {
        type: 4,
        title: "接单人",
        ids: form.user,
        node_id: this.generateNodeId("order")
      }
      let data = {
        node,
        note: form.note,
        status
      };
      this.operateFlow(data).then(() => {
        this.$message.success(status == 1 ? '已派单' : '已转交');
      });
    },
    operateFlow(data) {
      return new Promise(resolve => {
        let url = '/admin/apply/add-node';
        let param = {
          ...data,
          id: this.info.message.id
        }
        this.$axios({
          url,
          method: 'POST',
          data: param
        }, res => {
          if (res.error == 0) {
            resolve();
            this.$emit("operate");
          } else {
            this.$message.warning(res.msg)
            this.loading = false;
          }
        }, () => {
          this.loading = false;
        });
      })
    },
    operateMessage(data) {
      return new Promise(resolve => {
        let url = `/admin/apply-message/${this.info.message.id}`;
        this.loading = true;
        this.$axios({
          url,
          method: 'PATCH',
          data: data
        }).then(() => {
          resolve();
          this.loading = false;
          this.$emit("operate");
        }).catch(() => {
          this.loading = false;
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
  .detail {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
  }
  .detail-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @modal-mask-bg;
  }

  .detail-ctx{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: 10vh auto 0;
    width: 600px;
    max-height: 70vh;
    border: var(--border);
    background-color: @component-background;
    font-size: 14px;
    .icon-close {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 24px;
      text-align: center;
      color: @text-color-secondary;
      cursor: pointer;
    }
  }
</style>

<template>
    <div class="video-view">
        <div class="video-area">
            <div class="video-box">
                <video autoplay controls :poster="video.poster" v-if="video && video.url">
                    <source :src="video.url" type="video/mp4">
                </video>
            </div>
            <div class="video-bar" v-if="video">
                <div class="video-title">{{video.title}}</div>
                <div class="video-btn">
                    <div class="btn-icon" @click="download">
                        <a-icon type="download" />
                    </div>
                    <div class="btn-icon" @click="close">
                        <a-icon type="close"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {downLoadFile} from '../common/js/tool';
    export default {
        name: "video-view",
        props: {
            video: Object
        },
        data() {
            return {

            }
        },
        methods: {
            close() {
                this.$emit("close");
            },
            download() {
                let video = this.video;
                let url = video.url;
                let name = url.substr(url.lastIndexOf("/") + 1);
                downLoadFile(video.url, name);
            }
        }
    }
</script>

<style scoped lang="less">
    .video-view {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.8);
        z-index: 1000;
    }
    .video-area {
        margin: 5vh auto 0;
        width: 142vh;
    }
    .video-box {
        height: 80vh;
        background-color: #000;
        video {
            width: 100%;
            height: 100%;
        }
    }
    .video-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
        background-color: #000000;
        color: @component-background;
        .video-title {
            font-size: 16px;
        }
        .video-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 30px;
            line-height: 1;
            .btn-icon {
                margin-left: 10px;
            }
        }
        .icon-close {
            margin-left: 20px;
        }
    }
</style>

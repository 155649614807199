<template>
    <div class="content md flex-box justify-center">
        <form-area
            ref="detailForm"
            class="password-form"
            layout="horizontal"
            confirm-text="修改"
            :label-col="{span: 6}"
            :wrapper-col="{span: 18}"
            :items="items"
            :entity="form"
            @cancel="cancel()"
            @confirm="modify"></form-area>
    </div>
</template>

<script>
    export default {
        name: "Password",
        data() {
            return {
                form: {},
                items: [
                    {
                        key: 'old',
                        label: '旧密码',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入旧密码',
                            type: "password"
                        },
                        rules: [{ required: true, message: '请输入旧密码', trigger: 'blur' }]
                    },
                    {
                        key: 'password',
                        label: '新密码',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入新密码',
                            type: "password"
                        },
                        rules: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
                    },
                    {
                        key: 'second',
                        label: '再次输入新密码',
                        component: 'a-input',
                        props: {
                            placeholder: '请再次输入新密码',
                            type: "password"
                        },
                        rules: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }]
                    },
                ],
                loading: false,
            }
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            modify(form) {
                if(this.loading) return;
                if(form.password !== form.second) {
                    this.$message.warning('两次输入的新密码不一致');
                    return;
                }
                this.loading = true;
                this.$axios({
                    url: '/admin/change-password',
                    method: 'POST',
                    data: {
                        old_password: form.old,
                        new_password: form.password,
                    }
                }).then(res => {
                    this.loading = false;
                    if(res.code == 0) {
                        this.$success({
                            title: '修改成功',
                            content: '请使用新密码重新登录',
                            onOk() {
                                location.replace( '/login');
                            }
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>
    .password-form {
        width: 800px;
    }
</style>

<template>
    <div class="ctx-title">
        <a-breadcrumb class="title-routes" :routes="routes">
            <template v-slot:itemRender="{route}">
                <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
                <router-link :to="route.path" v-else>{{route.breadcrumbName}}</router-link>
            </template>
        </a-breadcrumb>
        <div class="title-sub-menu" v-if="subMenus">
            <a-menu class="sub-menu-area" v-model="current" mode="horizontal" @click="handleClick">
                <a-menu-item v-for="m in subMenus" :key="m.path">{{m.title}}</a-menu-item>
            </a-menu>
        </div>
        <div class="title-slot flex-box justify-center" v-else-if="title">
            <title-slot :vnodes="title"></title-slot>
        </div>
    </div>
</template>

<script>
    import {getSysList} from "../permission/subsystem";
    import entityConfig from "../common/constant/entity";
    export default {
        name: "title-bar",
        components: {
            'title-slot': {
                functional: true,
                render: (h, ctx) => ctx.props.vnodes
            }
        },
        data() {
            return {
                routes: [],
                current: [this.$route.path]
            }
        },
        computed: {
            title() {
                return this.$store.getters.title;
            },
            subMenus() {
                return this.$store.getters.subMenus;
            },
            temp() {
                return this.$store.getters.temp;
            },
            safe() {
                return this.$store.getters.safeRoute;
            },
        },
        watch: {
            $route(val) {
                this.current = [val.path];
                this.setRoutes();
            },
            temp() {
                this.setRoutes();
            },
            safe() {
                this.setRoutes();
            }
        },
        created() {
            this.setRoutes();
        },
        methods: {
            setRoutes() {
                let matched = this.$route.matched;
                let params = this.$route.params;
                let type = params.type;
                let sys = params.sys;
                let temp = this.temp;
                if(matched.length <= 0) return;
                let routes = [];
                const sysList = getSysList();
                matched.forEach(route => {
                    let name = route.name;
                    let entity = entityConfig[type];
                    switch (name) {
                        case 'Home':
                            if(sysList[sys]) {
                                routes.push({
                                    path: sysList[sys].path,
                                    breadcrumbName: sysList[sys].name
                                })
                            }
                            break;
                        case 'Entity':
                        case 'CheckinHome':
                            break;
                        case 'List':
                            routes.push({
                                path: `/sys/${sys}/entity/${type}`,
                                breadcrumbName: entity.listTitle ? entity.listTitle : `${entity.name}管理`
                            })
                            break;
                        case 'AddEntity':
                            routes.push({
                                path: `/sys/${sys}/entity/${type}/add`,
                                breadcrumbName: `添加${entity.name}`
                            })
                            break;
                        case 'EntityDetail':
                            routes.push({
                                path: `/sys/${sys}/entity/${type}/detail/${params.id}`,
                                breadcrumbName: `${entity.name}详情`
                            })
                            break;
                        case 'Template':
                            routes.push({
                                path: '/oa/template',
                                breadcrumbName: '应用管理'
                            })
                            break;
                        case 'TemplateData':
                        case 'TemplateForm':
                        case 'TemplateSetting':
                        case 'TemplatePlate':
                            if(temp) {
                                routes.push({
                                    path: route.path,
                                    breadcrumbName: temp.name
                                })
                            }
                            break;
                        case 'HRBook':
                            routes.push({
                                path: route.path,
                                breadcrumbName: { monk: '僧众档案', staff: '职工档案', other: '其他人员'}[type]
                            })
                            break;
                        case 'VolunteerTemplate':
                            routes.push({
                                path: '/hr/volunteer/app/template',
                                breadcrumbName: '应用管理'
                            })
                            break;
                        case 'VolunteerTemplateData':
                        case 'VolunteerTemplateForm':
                        case 'VolunteerTemplateSetting':
                        case 'VolunteerTemplatePlate':
                            if(temp) {
                                routes.push({
                                    path: route.path,
                                    breadcrumbName: temp.name
                                })
                            }
                            break;
                        case 'UseTemplateData':
                            break;
                        case 'UseTemplateSetting':
                            break;
                        case 'BorrowTemplateData':
                            break;
                        case 'BorrowTemplateSetting':
                            break;
                        case 'BuddhaTypeAdd':
                        case 'BuddhaTypeDetail':
                            routes.push({
                                path: '/buddha/bl',
                                breadcrumbName: '预约设置'
                            }, {
                                path: location.pathname,
                                breadcrumbName: route.meta.title
                            })
                            break;
                        case 'Checkin':
                            routes.push(
                              { path: '/oa/template', breadcrumbName: "应用管理" },
                              { path: route.path, breadcrumbName: "打卡设置" }
                            );
                            break;
                        case 'SafeSmokeData':
                            break;
                        case 'SafeSmokeList':
                            break;
                        case 'SafeSmokeRecord':
                            break;
                        case 'SafeSmokeWork':
                            break;
                        case 'SafeWaterData':
                            break;
                        case 'SafeWaterList':
                            break;
                        case 'SafeWaterRecord':
                            break;
                        case 'SafeWaterWork':
                            break;
                        case 'SafePowerData':
                            break;
                        case 'SafePowerList':
                            break;
                        case 'SafePowerRecord':
                            break;
                        case 'SafePowerWork':
                            break;
                        case 'SafeMonitorData':
                            break;
                        case 'SafeMonitorList':
                            break;
                        case 'SafeMonitorRecord':
                            break;
                        case 'SafeMonitorWork':
                            break;
                        case 'SafeTrafficData':
                            break;
                        case 'SafeTrafficList':
                            break;
                        case 'SafeTrafficRecord':
                            break;
                        case 'SafePlaqueData':
                            break;
                        case 'SafePlaqueList':
                            break;
                        case 'SafePlaqueRecord':
                            break;
                        case 'SafePlaqueWork':
                            break;
                        case 'SafeMassifData':
                            break;
                        case 'SafeMassifList':
                            break;
                        case 'SafeMassifRecord':
                            break;
                        case 'SafeMassifWork':
                            break;
                        case 'SafeCarList':
                            break;
                        case 'SafeCarData':
                            break;
                        case 'SafeCarForm':
                            break;
                        case 'SafeCarSetting':
                            break;
                        case 'SafeCarPlate':
                            break;
                        case 'SafeMeteorologyData':
                            break;
                        case 'SafeMeteorologyRecord':
                            break;
                        default:
                            routes.push({
                                path: location.pathname,
                                breadcrumbName: route.meta.title
                            })
                            break;
                    }
                });
                this.routes = routes;
            },
            handleClick(e) {
                let path = e.key;
                if(path != this.$route.path) {
                    let item = this.subMenus.find(item => item.path == path);
                    if(item && item.blank) {
                        window.open(path, "_blank");
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .ctx-title {
        flex-shrink: 0;
        position: relative;
        padding: @padding-md;
        min-height: 54px;
        font-size: @font-size-lg;
    }
    .title-routes {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        padding: 0 36px;
        height: 20px;
        line-height: 20px;
        z-index: 1;
    }
    .village-switch {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        padding: 0 @padding-md;
        font-size: 14px;
    }
    .title-sub-menu {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .sub-menu-area {
        background-color: transparent;
    }
</style>

<template>
  <div class="main" :class="{collapsed}">
    <div class="left flex-box vertical">
      <div class="logo-img-box">
        <div class="logo-title">{{title || '智慧寺院综合应用场景'}}</div>
      </div>
      <menu-bar class="flex-grow"></menu-bar>
    </div>
    <div class="right flex-box vertical">
      <nav-bar></nav-bar>
      <title-bar></title-bar>
      <div class="area">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
    <apply-detail :apply="apply" :report="apply && apply.report" v-show="!!apply" @hide="$store.commit('setApply', null)"></apply-detail>
    <img-viewer :list="imgList" :current="current" v-show="viewerShow" @close="hideViewer"></img-viewer>
    <video-view :video="viewVideo" v-show="!!viewVideo" @close="$store.commit('setViewVideo', null)"></video-view>
  </div>
</template>

<script>
import menuBar from '../components/menu-bar'
import navBar from '../components/nav-bar';
import titleBar from '../components/title-bar';
import imgViewer from "../components/img-viewer";
import videoView from "../components/video-view";
import applyDetail from "../components/apply-detail/apply-detail";
import {getStorageInfo} from "../common/js/storage";
export default {
  name: 'Home',
  components: {
    navBar,
    menuBar,
    titleBar,
    imgViewer,
    videoView,
    applyDetail
  },
  data() {
    return {
      visible: true,
      title: getStorageInfo().temple.title
    }
  },
  computed: {
    collapsed () {
      return this.$store.getters.collapsed;
    },
    imgList() {
      return this.$store.getters.imgList;
    },
    current() {
      return this.$store.getters.current;
    },
    viewerShow() {
      return this.$store.getters.viewerShow;
    },
    viewVideo() {
      return this.$store.getters.viewVideo;
    },
    apply() {
      return this.$store.getters.apply;
    }
  },
  methods: {
    hideViewer() {
      this.$store.commit("setViewerShow", false);
    }
  }
}
</script>

<style lang="less">
.left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 256px;
  box-shadow: 6px 6px 15px rgba(31,81,164,.22);
  z-index: 11;
  transition: all 0.2s;
}
.right {
  position: absolute;
  top: 0;
  left: 256px;
  right: 0;
  bottom: 0;
  background-color: #F8F3E7;
  transition: all 0.2s;
}
.main.collapsed {
  .left {
    width: 80px;
  }
  .right {
    left: 80px;
  }
  .logo-title {
    width: 0;
  }
}
.logo-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #FF8C00;
  z-index: 1;
  .logo-img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: contain;
  }
  .logo-title {
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(59, 33, 32, 0.5);
  }
}
</style>
